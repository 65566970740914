import 'bootstrap/dist/css/bootstrap.min.css';
import Logo1 from '../../logos/Untitled-1.svg';
import { ConnectButton } from './connectButton';
import { Outlet, Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import bnblogo from '../../logos/bnb-bnb-logo.svg'
import spanishicon from '../../logos/spanish.svg'
import {ethers,formatEther} from 'ethers'
import { useTranslation } from 'react-i18next';
import englishicon from '../../logos/english.svg'
import discordicon from '../../logos/discordIcon.svg'




const Bootstrapbar = () => {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navigate = useNavigate();




  function useNavigateWithRefresh() {
    const navigate = useNavigate();

    function navigateAndRefresh(path, state = {}) {
      navigate(path, { state });
      // Optionally, you can delay the reload slightly to ensure navigation has occurred
      setTimeout(() => window.location.reload(), 50);
    }

    return navigateAndRefresh;
  }

  

  return (
    <nav className="navbar navbar-expand-lg navbb">

    <Link to={'/'} style={{textDecoration:'none'}} > <img className='siteLogo' src={Logo1} alt="Logo" style={{ height: '10em' }} /></Link>


      <div className="container-fluid" >
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={'intercambio'}  onClick={useNavigateWithRefresh}  style={{textDecoration:'none'}}><h3 className='navwords'>{t("Intercambio")}</h3> </Link>
            </li>
            <li className="nav-item">
              {/* <a className="navwords" href="#">Juegos</a> */}
              <Link to={'juegos'} style={{textDecoration:'none'}} ><h3 className='navwords'>{t('Juegos')}</h3> </Link>
            </li>
            <li className="nav-item">
              {/* <a className="navwords" href="#">Juegos</a> */}
              <Link to={'nft'} style={{textDecoration:'none'}} ><h3 className='navwords'>NFT</h3> </Link>
            </li>

            <li className="nav-item"> 
               {/* <a className="navwords" href="#">Aprender</a>  */}
               <Link to={'aprender'} style={{textDecoration:'none'}} ><h3 className='navwords'>{t("Aprender")}</h3> </Link>
            </li> 

            <li className="nav-item"> 
               {/* <a className="navwords" href="#">Aprender</a>  */}
               <Link to={'documentation'} style={{textDecoration:'none'}} ><h3 className='navwords'>{t("Informacion")}</h3> </Link>
            </li> 


            {/* <li className="nav-item">
              <a className="nav-link disabled">Agregar Liquidad (nuevo!)</a>
            </li> */}

          </ul>
          <a href='https://discord.com/channels/1247310889200390264/1250730894009045056'>
          <img src={discordicon} style={{height:'1.5em',paddingRight:'1em'}}></img>
          </a>
          <div>
        <button onClick={() => changeLanguage('en')}style={{ border: 'none', background: 'none', cursor: 'pointer' }}><img src={englishicon} alt="Español" /> English</button>
        <button onClick={() => changeLanguage('es')}  style={{ border: 'none', background: 'none', cursor: 'pointer' }}><img src={spanishicon} alt="Español" />
Español</button>
      </div>

          {/* <h7 style={{paddingRight:'1em',fontWeight:'bold'}}>Precio de 1 Churrito:</h7> */}
          <h5 style={{paddingRight:'1em',fontWeight:'bold'}}>Testing on BNB Testnet</h5>
          <ConnectButton/>
        </div>
      
      </div>
    </nav>
  );
};

export default Bootstrapbar;
