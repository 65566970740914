import React from 'react';
import Paper from './../../../src/whitepaperchurro.pdf'

const Pdfs = ({ file }) => {
    return (
        <div>
            <embed src={Paper} width="700" height="700" 
 type="application/pdf"></embed>
        </div>
    );
};

export default Pdfs;
