import React from 'react';
import Placements from './placements';
import Image1 from './../../../images/1178.png'
import Image2 from './../../../images/1546.png'
import Image3 from './../../../images/2272.png'
import Image4 from './../../../images/2285.png'
import Image5 from './../../../images/24.png'
import Image6 from './../../../images/28.png'
import Image7 from './../../../images/35.png'
import Image8 from './../../../images/516.png'
import Image9 from './../../../images/571.png'
import Image10 from './../../../images/1351.png'




import { height } from '@mui/system';

const App = () => {
  const images = [
    Image4,
    Image2,
    Image3,
  ];
  const images2 = [
    Image1,
    Image5,
    Image6,
  ];
  const images3 = [
    Image10,
    Image8,
    Image9,
  ];

  const images4 = [
    Image10,
    Image2,
    Image3,
  ];

  return (
    <div>
      <Placements images={images} images2={images2} images3={images3} images4={images4} />
    </div>
  );
};

export default App;
