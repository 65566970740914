import UnityGame from "./Unitycontext";
import React from "react";
import game1 from '../../logos/gamebackground.png'
import { useTranslation } from 'react-i18next';

import churroclickerlogo from '../../logos/clickerlogo-05.svg'
import { Link } from "react-router-dom";




const GamePage = () => {
      const { t } = useTranslation();

      const { i18n } = useTranslation();
    
      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      };
      
    return (

<Link to={'churroclicker'} style={{textDecoration:'none'}}>
{/* <h3 style={{color:'rgb(51, 20, 15)'}}><strong>Churro Clicker</strong></h3> */}
<img src={churroclickerlogo}></img>
<p style={{color:'rgb(51, 20, 15)'}}>{t("¡Cocina nuevos Churritos y obten $CHTO!")}</p>
<p style={{color:'rgb(51, 20, 15)',fontSize:'15px'}}>{t("¡Se requiere tener un Churrito NFT!")}</p>
      <div class="image-container">
            {/* <UnityGame/> */}
            
            <img src={game1} alt="clicker game" ></img>
         
      </div>

      </Link>

    );
  };

  export default GamePage;

