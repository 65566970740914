import game1 from '../../../logos/gamebackground.png'
import nftimg from '../../../logos/nftpageimg-01.jpg'
import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";
import logochurros from '../../../logos/churritoslogo-04.svg'
import Images from './images';



const NftPage = () => {

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

    return (


<Link to={''} style={{textDecoration:'none'}}>
    <img src={logochurros} style={{height:'7em'}}></img>
{/* <h3 style={{color:'rgb(51, 20, 15)'}}><strong>Los Churritos</strong></h3> */}
<p style={{color:'rgb(51, 20, 15)'}}>{t("2000 RARE Churritos with utility! Use them with games on ChurroSwap to earn rewards!  ")}</p>
<p style={{color:'rgb(51, 20, 15)'}}>{t("The collection includes: Unique backgrounds, bodies, clothing, accessories, eyes, mouths, and more! ")}</p>


      <div class="nftimg-container">
            {/* <UnityGame/> */}

            {/* <img src={nftimg} alt="clicker game" ></img> */}

      </div>
      <p style={{color:'rgb(51, 20, 15)'}}>{t("Available to mint on this page SOON!  ")}</p>



      <Images style={{height:'2em'}}/>

      </Link>

    );
  };

  export default NftPage;