import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import back from '../../logos/back1.png'
import char from '../../logos/c1.png'
import leccion from '../../logos/leccion.png'
import chart from '../../logos/chart.png'
import SimpleEmailCollector from '../Ui/email-collector';


export const FormSubmission = () => {
  

    return (
        <>
        <h3 style={{fontWeight:'bold'}}>¡Gracias por someter su correo. Estaremos en contacto!</h3>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        
</>
        
    );
}