import AccordionExpandIcon from './accordion' 

export const Course = () => {
  

    return (
        <div style={{paddingBottom:'10em'}}>
     <AccordionExpandIcon />
        </div>
    );
}