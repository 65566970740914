import React, { useState } from 'react';
import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setConnectedWallet, setAddress, setBalance } from '../../app/connectSlice';
import AlertDialogSlide from '../../components/Ui/Ui';

export const ConnectButton = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const dispatch = useDispatch();
  const [walletConnected, setWalletConnected] = useState(false);
  const isWalletConnected = useSelector((state) => state.connect.WalletConnected); // Adjust this path as necessary
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const accountAddress = useSelector((state) => state.connect.account);

  const main = async () => {
    if (window.ethereum) {
      try {
        const newProvider = new ethers.BrowserProvider(window.ethereum);
        const wallet = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const signer = newProvider.getSigner();
        const account = await ethers.getAddress(wallet[0]);
        const balance = await newProvider.getBalance(account);
        const formattedBalance = ethers.formatEther(balance);

        setProvider(newProvider);
        setWalletConnected(true);
        setAccount(account);
        setDialogOpen(true);

        console.log('account connected:', account.slice(0, 5));
        console.log(formattedBalance);
        console.log(signer);

        dispatch(setConnectedWallet(true));
        dispatch(setAddress(account));
        dispatch(setBalance(formattedBalance));
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log('Ethereum object not found, install MetaMask.');
    }
  };

  const disconnectWallet = () => {
    setProvider(null);
    setWalletConnected(false);

    dispatch(setConnectedWallet(false));
    dispatch(setAddress('Account Null'));
    dispatch(setBalance('0'));
    console.log('account disconnected');
  };

  return (
    <>
      <button
        className={`connect-button ${isWalletConnected ? 'connect-button-clicked' : ''}`}
        onClick={isWalletConnected ? disconnectWallet : main}
      >
        {isWalletConnected ? `${t('Conectado/a')}: ${accountAddress.slice(0, 5)}` : t('conectar')}
      </button>
      {dialogOpen && (
        <AlertDialogSlide
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          sx={{
            width: 500,
            innerHeight: 1000,
            color: 'green',
          }}
        />
      )}
    </>
  );
};