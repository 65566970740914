import React from 'react';



const TestPage = ({ text, onClick }) => {


  return (
    <div>
   <h3>
    Test
   </h3>


    </div>
    );
};










export default TestPage;
