import React from 'react';

const ImageRow = ({ images = [], images2=[],images3=[],images4=[] }) => {
  return (
    <div>
    <div className="image-row">
      {images.length > 0 ? (
        images.map((src, index) => (
          <img key={index} src={src} alt={`img-${index}`} className="image-item" />
        ))
      ) : (
        <p>No images available</p> // Fallback message if images array is empty
      )}
    </div>

<div className="image-row">
{images2.length > 0 ? (
  images2.map((src, index) => (
    <img key={index} src={src} alt={`img-${index}`} className="image-item" />
  ))
) : (
  <p>No images available</p> // Fallback message if images array is empty
)}
</div>

<div className="image-row">
{images3.length > 0 ? (
  images3.map((src, index) => (
    <img key={index} src={src} alt={`img-${index}`} className="image-item" />
  ))
) : (
  <p>No images available</p> // Fallback message if images array is empty
)}
</div>

</div>
  );
};

export default ImageRow;
