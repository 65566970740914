import UnityGame from "./Unitycontext";
import React from "react";
import game1 from '../../logos/gamebackground.png'
import { Link } from "react-router-dom";




const ChurroClicker = () => {


    return (


           <UnityGame/>



    );
  };

  export default ChurroClicker;

