import { createSlice } from '@reduxjs/toolkit'

const initialState =
  { WalletConnected: 'Wallet Not Connected', account: 'Account Null' , balance:'User Balance Null',}


const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {

      setConnectedWallet: (state, action) => {
        state.WalletConnected = action.payload;
      },
      setAddress: (state, action) => {
        state.account = action.payload;
      },
      setBalance: (state, action) => {
        state.balance = action.payload;
      },


  }
})

export const {setConnectedWallet, setAddress, setBalance} = connectSlice.actions;

export default connectSlice.reducer