import Pdfs from "./pdfs";
import samplePdf from './../../../src/whitepaperchurro.pdf';

export const Docs = () => {
  

    return (
        <div style={{paddingBottom:'10em'}}>
     {/* <Pdfs /> */}
     
     <Pdfs/>
        </div>
    );
}