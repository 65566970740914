import { configureStore } from '@reduxjs/toolkit'
import connectSlice from './connectSlice'
import storage from 'redux-persist/lib/storage'; 
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';


const persistConfig = {
    key: 'root', // The key for the persisted state
    storage, // The storage engine to use (localStorage in this case)
};

const rootReducer = combineReducers({
    connect: connectSlice,
    // Add other slices here as needed
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
    // Add any middleware or enhancers here
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };