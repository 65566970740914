import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Link } from "react-router-dom";
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import back from '../../logos/back1.png'
import char from '../../logos/c1.png'
import leccion from '../../logos/leccion.png'
import chart from '../../logos/chart.png'
import SimpleEmailCollector from '../Ui/email-collector';
import './../../../src/il8n';

export const Home = () => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };



    return (
        <>
        <SimpleEmailCollector/>
        <div style={{paddingBottom:'5em'}}>
            <h3 style={{fontWeight:'bold',fontSize:'2em',marginLeft:'5em',marginRight:'5em'}}>
               {t("Tu lugar para aprender, ganar, y intercambiar criptomonedas!")}
            </h3>
        </div>
      
       
<Grid container spacing={2}>
<Grid item xs={12} sm={6} >
<Link to={'intercambio'} style={{textDecoration:'none'}}>
  <Card sx={{ height: '300px',backgroundColor:'#c9beae',marginLeft:'4.5em' , borderRadius:'20px',transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'rotate(10deg) scale(1.05)', // Rotate 15 degrees and scale up
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
              backgroundColor: '#f9bd4c' // Shadow effect for pop out look
            }, }}>
    <CardContent>
      <Typography variant="h5" component="div">
      {t("Intercambia")}
      </Typography>
      <Typography variant="body2">
        {t("Intercambia criptomonedas con bajas tarifas")}
      </Typography>
     <img src={back} style={{height:'10em'}}></img>
     
    </CardContent>
  </Card>
  </Link>
</Grid>
<Grid item xs={12} sm={6}>
<Link to={'juegos'} style={{textDecoration:'none'}}>

  <Card sx={{ height: '300px',marginRight:'4.5em',backgroundColor:'#c9beae',borderRadius:'20px' ,transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'rotate(10deg) scale(1.05)', // Rotate 15 degrees and scale up
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)', // Shadow effect for pop out look
              backgroundColor: '#f9bd4c'
            },  }}>
    <CardContent>
      <Typography variant="h5" component="div">
       {t("Juega")}
      </Typography>
      <Typography variant="body2">
        {t("Gana criptomonedas con nuestros juegos!")}
      </Typography>
      <img src={char} style={{height:'10em'}}></img>
    </CardContent>
  </Card>
  </Link>
</Grid>
<Grid item xs={12} sm={6}>
<Link to={'aprender'} style={{textDecoration:'none'}}>
  <Card sx={{ height: '300px',backgroundColor:'#c9beae',marginLeft:'4.5em' ,borderRadius:'20px',transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'rotate(10deg) scale(1.05)', // Rotate 15 degrees and scale up
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)', // Shadow effect for pop out look
              backgroundColor: '#f9bd4c'
           },  }}>
    <CardContent>
      <Typography variant="h5" component="div">
        {t("Aprende")}
      </Typography>
      <Typography variant="body2">
        {t("Tenemos lecciones para que estes informado/a!")}
      </Typography>
      <img src={leccion} style={{height:'10em'}}></img>
    </CardContent>
  </Card>
  </Link>
</Grid>
<Grid item xs={12} sm={6}>
<Link to={'aprender'} style={{textDecoration:'none'}}>
  <Card sx={{ backgroundColor:'#c9beae', height: '300px',marginRight:'4.5em' ,borderRadius:'20px',transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'rotate(10deg) scale(1.05)', // Rotate 15 degrees and scale up
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)', // Shadow effect for pop out look
              backgroundColor: '#f9bd4c'
            },  }}>
    <CardContent>
      <Typography variant="h5" component="div">
        {t("Invierte")}
      </Typography>
      <Typography variant="body2">
        {t("Aprende como invertir en cripto!")}
      </Typography>
      <img src={chart} style={{height:'10em'}}></img>
    </CardContent>
  </Card>
  </Link>
</Grid>
</Grid>
</>
        
    );
}