import Button from '../swap/button';
import '../../../src/App.css'
import Trade from './trade'
import AlertDialogSlide from '../../components/Ui/Ui'

const clickHandler =(event)=>{

  console.log("clicked")

}




const SwapPage = () => {

  return (
    <div className="Swap-Background">
        <Trade>
        </Trade>
        <AlertDialogSlide/>




        
        {/* <Button
          text={  "SWAP"}
          onClick={clickHandler}
          className="swapButton"
        ></Button> */}


     
    </div>
  );
};

export default SwapPage;
