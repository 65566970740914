import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import success from '../../success.png';



const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  borderRadius: theme.spacing(2), // Adjust the value to change the roundness of the corners
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Styles for the close button
const CloseButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(51, 20, 15,0.7)', // Change the background color to green on hover
  },
}));

// Props for controlling visibility and handling close action
export default function TransactionConfirm({ open, onClose }) {




  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ // Set PaperProps to apply styles to the dialog container
        style: {
          borderRadius: '20px', // Adjust the value to change the roundness of the corners
        },
      }}
    >
      <h3 style={{background:'lightgreen',color:'white'}}>success success success success</h3>
      <DialogTitle style={{ textAlign: 'center',color: 'rgb(51, 20, 15)',fontWeight:'bold' }} id="alert-dialog-slide-title">{"Success!"} <div style={{ marginTop: '20px', textAlign: 'center' }}>

        </div></DialogTitle>
      <DialogContent>
        <img src={success} alt="Funny" style={{ height: '15em' }}/>
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={onClose} style={{ color: 'black',fontWeight:'bold' }}>Close</CloseButton>
      </DialogActions>
    </StyledDialog>
  );
}
